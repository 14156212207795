import {
  Center,
  Container,
  VStack,
  HStack,
  Heading,
  Text,
  SimpleGrid,
  Link,
  Box,
  Image,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { TextUnderline } from '../components/atoms';
import { Footer, Header } from '../components/molecules';
import { AiFillContainer } from 'react-icons/ai';
import { BiTable } from 'react-icons/bi';
import Loader from '../components/molecules/Loader';

const Incoterms = () => {
  return (
    <>
      <Helmet>
        <title>Resources | GFL Group of Companies</title>

        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="icon"
        ></link>
        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="apple-touch-icon"
        ></link>

        <meta
          content="width=device-width, initial-scale=1.0"
          name="viewport"
        ></meta>
        <meta
          content="logistics, suppliers, shipping, warehousing, malaysian, malaysia, klang, freight, air freight, ship freight, rail freight, cargo, cargo express"
          name="keywords"
        />
        <meta
          name="description"
          content="Your solution to total cargo transport"
        />
        <meta name="robots" content="index, follow"></meta>
        <link rel="canonical" href="https://www.gflgroup.com.my" />
        <meta
          property="og:title"
          content="Resources - GFL | Your solution to total cargo transport"
        ></meta>
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:description"
          content="Your solution to total cargo transport"
        ></meta>
        <meta property="og:url" content="https://www.gflgroup.com.my"></meta>
        <meta
          property="og:site_name"
          content="Resources - GFL | Your solution to total cargo transport"
        ></meta>
        <meta
          property="og:image"
          content="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
        ></meta>
        <meta property="twitter:card" content="summary large image"></meta>
        <meta
          property="twitter:label1"
          content="Your solution to total cargo transport"
        ></meta>
      </Helmet>
      <Loader /> <Header />
      <Image src={'/resources/incoterms2020rules.svg'} />
      <Footer />
    </>
  );
};

export default Incoterms;
